﻿
$background-color: rgba(241, 245, 248, .5);

body.preload * { // ".preload" class is fix for IE transition jump on page loads - it should be removed on $(document).ready(). See https://css-tricks.com/transitions-only-after-page-load/
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
}

body {
    background-color: $background-color;

    &.sidebar-hidden {

        div#body-wrapper {
            margin-left: 50px;
        }
    }
    /* Trying to fix font not rendering */
    -webkit-animation-delay: 0.1s;
    -webkit-animation-name: fontfix;
    -webkit-animation-duration: 0.1s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: linear;
}

a {
  cursor: pointer;
}

.error-actions {
    margin-top: 20px;
}

.error-id {
    margin-top: 10px;
}

.align-items-center {
    align-items: center;
}

.flex-container {
    display: flex;
}

p.no-margin-bottom {
    margin-bottom: 0;
}

@-webkit-keyframes fontfix {
    from {
        opacity: 1;
    }

    to {
        opacity: 1;
    }
}

div#body-wrapper {
    margin-left: $left-side-menu-width;
    transition: margin-left $left-side-menu-transition-duration;
    margin-bottom: 30px;
}

div.body-content-full-width {
    margin-top: 20px;

    .nav-tabs {
        padding-left: 50px;
    }
}

.title-bar-wrapper {
  position: fixed;
  top: 61px;
  left: 0px;
  width: 100%;
}

.error-message {
  margin: 100px;
}

div.body-content {
    margin-top: var(--margin-top);
    margin-left: 0px;
    padding-left: 50px;
    padding-right: 50px;
}

.body-container {
    .body-content {
        padding-top: 0;
    }
}

.loading-spinner {
  margin-top: 100px;
}

div.word-wrap {
    word-wrap: break-word;
}

@media (max-width: 767px) {

    div.body-content-full-width {
        margin-top: 10px;

        .nav-tabs {
            padding-left: 15px;
        }
    }

    div.body-content {
        padding-left: 15px;
        padding-right: 15px;
    }

    div#body-wrapper {
        margin-left: 0px !important;
    }
}

.clickable-row {
    cursor: pointer;
}

.exclude-click-event {
    cursor: default;
}

.break-word {
    word-break: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}
