@import "../../content/styles/embla-variables.scss";

.navbar {
  background-color: #FFFFFF;
}

.navbar-header {
  color: $color-primary;
  font-size: 20px;
  word-spacing: -1px;
}

.navbar-collapse {
  max-width: 100%;
}

.instructions-container {
  white-space: nowrap;

  svg {
    color: $color-primary !important;
    fill: $color-primary !important;
  }

  .instruction-text {
    color: $color-primary;
    font-size: 12px;
  }
}

.project-title-container {
  overflow: hidden;
  flex: 1;
  min-width: 125px;
  display: flex;
  align-items: center;
}

.project-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 33px;
}

.action-buttons-container {
  margin-left: auto;
  display: flex;
  align-items: center;
}
