.title-bar {
  border-bottom: 0px;
}

.title-bar-header {
    display: flex;
    align-items: center;

    .title-bar-status {
        margin-left: 20px;
    }
}

.status-pill {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-gray-95;
    height: 32px;

    .status-pill-text {
      color: #000d7a;
        font-size: 14px;
        font-weight: $font-weight-normal;
        padding: 0 16px;
    }
}

.sub-title-bar {
  --subtitle-container-width: 620px;

  @media only screen and (max-width: 1299px) {
    --subtitle-container-width: 100%;
  }

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  --action-container-min-width: 572px;

  .sub-title {
    width: var(--subtitle-container-width);
    font-size: small;

    span {
      margin-right: 4px;
    }
  }

  .sub-title-bar-actions {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    min-width: var(--action-container-min-width);

    @media only screen and (max-width: 1299px) {
      justify-content: flex-start;
      margin-top: $margin-m;
    }
  }
}
