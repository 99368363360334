.selectable {
  cursor: pointer;

  &:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef;
  }
}

.hideInformation {
  visibility: hidden;
}

.addSubcategoryContainer {
  max-height: 375px;
  overflow-y: scroll;
}

.addSubcategoryContainer::-webkit-scrollbar {
  display: none;
}
