﻿@mixin sticky($top, $left) {
  position: sticky;
  top: $top;
  left: $left;
}

@mixin institution-pill-styles($opacity: 1) {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2791F5;
  height: 25px;
  margin: 5px;
  opacity: $opacity;

  .institution-pill-text {
    font-size: 0.75rem;
    font-weight: $font-weight-normal;
    color: white;
    padding: 4px 8px;
    letter-spacing: 1px;
  }
}

$figma-line-height: 20px;
$first-column-width: 200px;
$header-zindex: 640;
$sidebar-header-zindex: 899;
$sidebar-zindex: 520;
$budgetstickyPoint: 56px;
$text-area-subcategory-header: $first-column-width - 110px; // 110px is chosen by trial and error
$sub-category-header-indend: 28px;
$main-category-indend: 19px;

$width-of-year-header-cell: 33px;
$adjustment-header-year-stickyPoint: 4px;
$budget-header-year-stickyPoint: $budgetstickyPoint + $adjustment-header-year-stickyPoint;
$budget-header-cell-stickyPoint: $budgetstickyPoint + $width-of-year-header-cell;

$grid-header-bottom-background-color: #FFFFFF;
$category-header-background-color: #E7F0F9;
$sub-category-header-background-color: #F5F7F8;
$custom-categories-background-color: #FFFFFF;

.summation-content-row {
  position: sticky;
  height: 50px;
  left: 0px;
  bottom: 0px;
  background-color: $grid-header-bottom-background-color !important;
  z-index: 519;
  border-left-style: none !important;
  border-top: 1px solid $color-gray-85 !important;
  box-shadow: none;
}

.summation-sidebar-row {
  position: sticky;
  height: 50px;
  left: 0px;
  bottom: 0px;
  background-color: $grid-header-bottom-background-color;
  border-style: none;
  border-top: 1px solid $color-gray-85;
  box-shadow: none;
  z-index: 999;
}

.summation-sidebar-custom-row {
  height: 50px;
  position: sticky;
  left: $first-column-width;
  bottom: 0px;
  z-index: 999;
  background-color: $grid-header-bottom-background-color;
  border-style: none;
  border-top: 1px solid $color-gray-85;
}

.budget-container {
  width: fit-content;
  height: fit-content;
  display: grid;
  line-height: $figma-line-height;
  grid-template-columns: $first-column-width var(--customCategoriesWidth) var(--institutionWidth) var(--yearColumnns);
  border-right: 1px solid $color-gray-85;
  box-sizing: border-box;
  border-radius: 4px;

  .sidebar-element {
    @include sticky(0, 0);
    z-index: $sidebar-zindex;

    &.header-title {
      z-index: $sidebar-header-zindex;
      @include sticky($budgetstickyPoint, 0);
      background-color: $grid-header-bottom-background-color;

      h5 {
        font-weight: $font-weight-bolder;
      }
    }

    & + .header-year-cell,
    & + .category-row-cell,
    & + .send-btn {
      border-left: 0;
    }

    institution-width {
      width: 1000px;
    }
  }

  .pill-first-dropdown-container {
    display: flex;
    flex-direction: row;
  }

  .first-second-dropdown-container {
    display: flex;
  }

  .pill-container {
    display: flex;
  }

  .institution-pill {
    @include institution-pill-styles(0.7);
  }

  .institution-pill-supplementrow {
    @include institution-pill-styles();
  }

  .institution-dropdown .btn-default {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2791F5;
    height: 25px;
    margin: 5px;
    border-radius: 20px;
    font-weight: $font-weight-normal;
    color: white;
    max-width: 100%;
  }

  .institution-dropdown .btn-default svg {
    fill: white;
  }

  .supplement-dropdown .btn-default {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333333;
    height: 25px;
    margin: 5px;
    border-radius: 20px;
    color: white;
    font-weight: $font-weight-normal;
    max-width: 100%;
  }

  .supplement-dropdown .btn-default svg {
    fill: white;
  }

  .custom-categories-sidebar-element {
    @include sticky($budgetstickyPoint, $first-column-width);
    z-index: $sidebar-zindex;
    background-color: $custom-categories-background-color;

    &.header-title {
      z-index: $sidebar-header-zindex;
      @include sticky(null, $first-column-width);
      background-color: $grid-header-bottom-background-color;
    }

    &.category-row-cell {
      border-left: 1px solid $color-gray-85;
    }

    &.open-costom-sidebar-element {
      border-right: 1px solid $color-gray-85;
    }
  }

  .custom-categories-title {
    font-weight: bolder;
  }

  .header-year-cell {
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column: span var(--columnSpan);
    @include sticky($budget-header-year-stickyPoint, 0);
    z-index: $header-zindex;
    font-weight: $font-weight-bolder;
    background-color: $grid-header-bottom-background-color;
  }

  #total-header {
    grid-row-start: 1;
    grid-row-end: 3;
    display: flex;
    justify-content: center;
    @include sticky($budgetstickyPoint, 0);
    z-index: $header-zindex;
    background-color: $grid-header-bottom-background-color;

    h3 {
      align-self: center;
    }
  }

  .header-title {
    grid-row-start: 1;
    grid-row-end: 3;
    display: flex;

    h3,
    h5 {
      align-self: flex-end;
    }

    #openCustomCategories {
      padding: 6px;
    }
  }

  .send-btn {
    grid-column: span var(--totalColumns);
    display: flex;

    .btn-wrapper {
      margin-left: auto;
      position: sticky;
      right: 1rem;
    }

    #download-excel-btn {
      margin-left: auto;
      position: sticky;
      margin-right: $margin-s;
    }
  }

  .header-cell {
    grid-row-start: 2;
    grid-row-end: 2;
    display: flex;
    @include sticky($budget-header-cell-stickyPoint, 0);
    z-index: $header-zindex;
    background-color: $grid-header-bottom-background-color;

    h4 {
      align-self: center;
    }

    &.readonly {
      h4 {
        opacity: 0.4;
      }
    }

    h5 {
      font-weight: $font-weight-bolder;
    }
  }

  .toggle-subcategories {
    cursor: pointer;
  }

  .category-row-cell {
    display: flex;
    align-items: start;
    background-color: #fff;

    &.editable {
      cursor: pointer;

      &.error {
        background-color: #FFD6D6;
      }
    }

    &.dragable {
      cursor: move;
    }

    .reorder-icon {
      margin-left: -8px;
      height: 20px;
      width: 20px;
    }

    .subcategory-actions {
      cursor: pointer;
    }

    .subcategory-header-write-mode {
      padding-left: $sub-category-header-indend;
    }

    .subcategory-header-readOnly-mode {
      padding-left: $sub-category-header-indend + 20px;
    }

    .action-icon {
      height: 20px;
      width: 20px;
    }

    .tag-icon-hover {
      &:hover {
        cursor: default;
      }
    }

    h5 {
      font-weight: $font-weight-bolder;
    }

    .add-subcategory-btn {
      padding: 4px;
    }

    &.readonly {
      background-color: $category-header-background-color;
    }

    &.readonly-single-column {
      background-color: $sub-category-header-background-color;
      cursor: text;
    }

    .category-row-cell-content {
      width: 100%;
      height: 100%;
      cursor: pointer;
      display: flex;
      align-items: start;
      overflow-wrap: anywhere;

      input {
        padding-right: 15px;
        width: 100%;
        height: 100%;
        text-align: right;
      }

      input:focus-visible {
        outline: none;
      }

      &.content-align-right {
        justify-content: flex-end;
      }

      &.content-align-center {
        justify-content: center;
      }

      &.text-cell {
        input {
          text-align: left;
          margin-right: 0;
          margin-left: -4px;
        }
      }

      &.readonly {
        cursor: text;
      }

      &.comparer-cell {
        cursor: text;
      }

      &.sum-cell {
        cursor: text;
        font-weight: $font-weight-bold;
      }

      .empty-badge {
        svg {
          margin-right: 0px;
        }
      }
    }

    .description-collapsed-icon {
      margin: auto;
    }
  }

  .border-bottom-left {
    border-bottom: 1px solid $color-gray-85;
    border-left: 1px solid $color-gray-85;
  }

  .subcategory-tags {
    width: 100%;

    div {
      &[role='button']:hover {
        border-radius: 10px;
        background-color: $color-gray-85;
      }
    }
  }

  .collapse-btn {
    padding: 4px;
  }
}

.single-line-sidebar-header-style {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 190px;
}

.category-header-background-color {
  background-color: $category-header-background-color !important;
}

.subcategory-header-background-color {
  background-color: $sub-category-header-background-color !important;

  h5 {
    font-style: normal;
    font-weight: $font-weight-normal !important;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }

  svg {
    fill: #c7b2b2;

    &.hoverItem {
      fill: #555 !important;
    }
  }
}

.single-line-description-style {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.multi-line-description-style {
  word-wrap: break-word;
  overflow: auto;
  max-width: 100%;
}

.single-line-sidebar-header-style {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: $text-area-subcategory-header;
}

.hide-element {
  visibility: hidden;
}

.sidebar-category-header-titel {
  padding-left: $main-category-indend;
}
