/*
    http://bootsnipp.com/snippets/featured/responsive-navigation-menu
*/
@import "embla-variables";

$break-lg: 1200px;
$break-md: 992px;
$break-sm: 768px;
$break-xs: 480px;

$left-side-menu-width: 250px !default;
$left-side-menu-transition-duration: 0.3s !default;
$header-border-color: #eee;
$header-border-color-dark: darken($header-border-color, 10%);