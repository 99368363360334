﻿.action-button {
  &.btn-default svg {
    fill: $color-primary !important;
  }

  &.line-state-button {
    min-width: 140px;
  }

  &.download-excel-button {
    min-width: 99px;
  }

  &.submit-button {
    min-width: 131px;
  }
}

.submit-modal-title {
  margin-top: $margin-l;
  font-weight: bold;
}

.submit-modal-text {
  margin-top: $margin-s;
}

.show-budgets-only-button {
  padding: 8px 16px !important;
  border-bottom: 1px solid $color-info !important;
  font-weight: bold !important;
  color: $color-primary !important;
}

.show-budgets-only-button:disabled {
  color: $color-gray-60 !important;
}

.version-dropdown .btn-default {
  color: black;
  font-weight: bold;
}

.echospook {
  visibility: hidden;
}
