.categories-dropdown {
  width: 200px;

  @media only screen and (max-width: 1400px) {
    width: 130px;
  }

  div {
    &[role='button']:hover {
      border-radius: 10px;
      background-color: $color-gray-85;
    }
  }
}
