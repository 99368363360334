
$background-text-area: #FFFFFF;

.textarea {
  resize: vertical;
  background-color: $background-text-area;

  &:focus-visible {
    outline: 1px solid black;;
    border-radius: 0px;
  }

  &.multiline {
    width: calc(100% - 1px);
    height: 100%;
  }
}

.textarea::-webkit-scrollbar {
  display: none;
}

