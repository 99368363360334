table.changelog-table {
    margin-bottom: 0;
    padding:0;
    border-collapse: separate;
    text-indent: initial;
    border-spacing: 2px;

    tr:first-child {
      td {
        border-top: 0;
      }
    }


    td {
      padding-bottom: 2px;
      padding-top: 2px;

      &:first-child {
        padding-left: 16px;
      }

      &:last-child {
        padding-right: 16px;
      }

      &.created-at {
        width: 150px;
      }

      &.created-by {
        min-width: 50px;
        white-space: nowrap;
      }
    }

    .changelog-data{
        width: 100%;
        border: 0;
        padding-bottom: 4px;
        padding-left: 16px;
        padding-top: 2px;
    }
}

.changelog-field-row{
    border-bottom: 1px solid lightgray;
}

#changelog-modal{
    .modal-body{
        padding: 0;
    }
}

.changelog-toggle{
    position: fixed;
    right: -10px;
    top: 67px;
    z-index: 1000;
    text-align: right;
    background-color: #FFFFFF;

    .sidenav-trigger {
        text-align: left;
        width: 50px;
        padding: 8px 14px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        transition: width 0.15s ease-in-out;
        white-space: nowrap;

        .list{
            width: 14px;
            height: 14px;
            margin-bottom: -3px !important;
            margin-right: 5px;
        }

        .hover-text{
            padding-left: 8px;
            font-weight: 550;
        }

        &:hover {
            width: 140px;
        }
    }
}




