@import "src/content/styles/embla-variables";

.tooltip {
  color: #000d7a;
  background: $color-white;
  padding: 4px 8px;
  border-radius: $border-radius;
  box-shadow: $shadow-1;
  overflow-wrap: break-word;
  z-index: 100000;
  min-width: 100px;
  max-width: 250px;
  text-align: center;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1), 0 6px 5px rgba(0, 0, 0, 0.1);
  font-size: 0.76563rem;
  text-align: left;
}

.tooltipInner {
  background-color: #fff;
}

.arrow,
.arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
}

.arrow::before {
  content: "";
  transform: rotate(45deg);
  background: $color-white;
}

.tooltip {

  &[data-popper-placement^="top"]>.arrow {
    bottom: -4px;
  }

  &[data-popper-placement^="bottom"]>.arrow {
    top: -4px;
  }

  &[data-popper-placement^="left"]>.arrow {
    right: -4px;
  }

  &[data-popper-placement^="right"]>.arrow {
    left: -4px;
  }
}
